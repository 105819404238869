import { handleErrors } from "utils/Fetch";

import Auth from "services/Auth";
import config from "utils/Config";

class _UserProfile {
	constructor() {
		this.callbacks = new Set(); 
	} 

	clear() {
		sessionStorage.removeItem("profile");
	}

	update(values) {
		UserProfile.clear();

		const token = Auth.getTokenInfo();
		if(!token) {
			return Promise.resolve(null);
		}

		const url = config().apiRoot + "/api/ekobina/profile/" + token.data.subject + "/update";
		return fetch(url, {
			method: "POST",
			cache: "no-cache",
			credentials: "same-origin",
			headers: { "Content-Type": "application/json;charset=UTF-8" },
			body: JSON.stringify(values)
		})
			.then(handleErrors)
			.then(() => {
				return this.get().then(profile => {
					this.callbacks.forEach(cb => {
						cb.call(profile);
					});
					return profile;
				});
			});
	}

	get() {
		let profile = sessionStorage.getItem("profile");

		try {
			if(profile) {
				profile = JSON.parse(profile);
			}
		} catch(error) {
			sessionStorage.removeItem("profile");
			profile = null;
		}

		if(profile) {
			return Promise.resolve(profile);
		} 
		
		const subject = Auth.getSubject();
		if(subject === "") {
			return Promise.resolve(null);
		}

		return Auth.getSubjectInfo().then(subjectInfo => {
			const url = config().apiRoot + "/api/ekobina/profile/" + subject;
			return fetch(url, {
				method: "GET",
				cache: "no-cache",
				credentials: "same-origin",
				referrer: "no-referrer",
				headers: { "Content-Type": "application/json;charset=UTF-8" },
			})
				.then(handleErrors)
				.then(response => response.json())
				.then(data => {
					data["subjectInfo"] = subjectInfo;
					sessionStorage.setItem("profile", JSON.stringify(data));
					return data;
				});
		});	
	}

	addListener(callback) {
		this.callbacks.add(callback);
	}

	removeListener(callback) {
		this.callbacks.delete(callback);
	}
}

const UserProfile = new _UserProfile();

export default UserProfile;