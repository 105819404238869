import React, { Component, lazy } from "react";

import PropTypes from "prop-types";
import { Link, Switch } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/icons";

import Loadable from "components/Loadable";
import PrivateRoute from "components/PrivateRoute";
// import logo from "images/ekobina_logo.svg";
import logo from "images/ecowise-logo-white.png";
import MainMenu from "./MainMenu";

const Overview = lazy(() => import("./Overview"));
const Profile = lazy(() => import("./Profile"));
const Buildings = lazy(() => import("./Buildings"));
const Reports = lazy(() => import("./Reports"));

const styles = theme => ({
	appBar: {
		[theme.breakpoints.up("md")]: {
			marginLeft: theme.menu.width,
			width: `calc(100% - ${theme.menu.width}px)`,
		},
	},
	fab: {
		height: "5.5rem"
	},
	logoLink: {
		marginLeft: "auto"
	},
	logo: {
		height: "3rem",
		// marginBottom: "0.5rem",
	},
	main: {
		[theme.breakpoints.down("sm")]: {
			padding: 0
        },
		[theme.breakpoints.up("md")]: {
			width: `calc(100% - ${theme.menu.width}px)`,
		},
		flexGrow: 1,
		//padding: theme.spacing(3),
		width: "100%"
	},
	menuIcon: {
		[theme.breakpoints.up("md")]: {
			display: "none",
		}
	},
	root: {
		display: "flex"
	},
	toolbar: theme.mixins.toolbar
});



class Main extends Component {
	state = {
		title: ""
	}

	constructor(props) {
		super(props);
		this.menu = React.createRef();
	}

	updateTitle() {
		const pages = this.props.location.pathname.split("/");

		let title = "";
		switch(pages[1]) {
			case "buildings":
				title = "Buildings";
				break;
			case "reports":
				switch(pages[2]) {
					case "control":
						title = "Reports / Control";
						break;
					case "sensor":
						title = "Reports / Sensor";
						break;
				}
				break;
			default:
				title = "Overview";
		}

		if(this.state.title !== title) {
			this.setState({ title: title });
		} 
	}

	componentDidMount() {
		this.updateTitle();
	}

	componentDidUpdate(prevProps, prevState) {
		this.updateTitle();
	}

	render() {
		const { classes } = this.props;
		const { title } = this.state;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar className={classes.appBar} position="fixed">
					<Toolbar>
						<IconButton aria-label="Menu" className={classes.menuIcon}
							color="inherit"
							onClick={() => this.menu.current.setOpen(true)}>
							<Menu />
						</IconButton>
						<Typography className={classes.grow} color="inherit" variant="h6">
							{title}
						</Typography>
						<Button component={Link} to="/" className={classes.logoLink}>
							<img alt="Ekobina" className={classes.logo} src={logo} />
						</Button>
					</Toolbar>
				</AppBar>

				<MainMenu innerRef={this.menu} />
				
				<main className={classes.main}>
					<div className={classes.toolbar} />
					<Switch>
						<PrivateRoute component={Loadable(Overview)} exact path="/" />
						<PrivateRoute component={Loadable(Profile)} path="/profile" />
						<PrivateRoute component={Loadable(Buildings)} path="/buildings" />
						<PrivateRoute component={Loadable(Reports)} path="/reports" />
					</Switch>
					<div className={classes.fab} />
				</main>
			</div>
		);
	}
}

Main.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Main);