export class FetchError extends Error {
	constructor(status, ...params) {
		super(...params);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, FetchError);
		}

		this.status = status;
	}
}

export function handleErrors(response) {
	if (!response.ok) {
		throw new FetchError(response.status, response.status + ":" + response.statusText);
	}
	return response;
}