import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faThermometerFull,
	faThermometerThreeQuarters,
	faThermometerHalf,
	faThermometerQuarter,
	faThermometerEmpty,
	faCheckCircle,
	faSnowflake,
	faFire,
	faSlidersH,
	faInfo,
	faInfoCircle,
	faTint,
	faMale,
	faFileExport,
	faUserCircle,
	faEdit,
	faClock,
	faMapMarkerAlt,
	faLayerGroup,
	faBuilding
} from '@fortawesome/free-solid-svg-icons'

export function faBuildLibrary() {
	library.add(
		faThermometerFull,
		faThermometerThreeQuarters,
		faThermometerHalf,
		faThermometerQuarter,
		faThermometerEmpty,
		faCheckCircle,
		faSnowflake,
		faFire,
		faSlidersH,
		faInfo,
		faInfoCircle,
		faTint,
		faMale,
		faFileExport,
		faUserCircle,
		faEdit,
		faClock,
		faMapMarkerAlt,
		faLayerGroup,
		faBuilding
	);
}
