import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { Component, lazy } from 'react';
import { 
    MuiThemeProvider, 
    createMuiTheme, 
    createSpacing } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import PrivateRoute from "components/PrivateRoute";
import Loadable from "components/Loadable";

import Logout from "components/Logout";
import Main from "./Main";

import 'bootstrap/dist/css/bootstrap.min.css';

import { faBuildLibrary } from "utils/faBuildLibrary";

faBuildLibrary();

const Login = lazy(() => import('./Login'));
const Consent = lazy(() => import('./Consent'));
const AuthCallback = lazy(() => import('./AuthCallback'));

const defaultTheme = createMuiTheme()
const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#31557C",
        },
        secondary: {
            main: "#FAA61A",
        },
        text: {
            primary: "#595959"
        }
    },
    typography: { useNextVariants: true },
    menu: { width: 300 },
    main: { spacing: defaultTheme.spacing(2) },
    mixins: {
        mainContent: {
            padding: defaultTheme.spacing(2),
            [defaultTheme.breakpoints.down("xs")]: {
                padding: 0
            }
        }
    },
    overrides: {
        MuiDrawer: {
            paper: {
                width: 300,
                maxWidth: "85%"
            }
        },
        MuiToolbar: {
            gutters: {
                paddingRight: defaultTheme.spacing(1),
                paddingLeft: defaultTheme.spacing(1),

                [defaultTheme.breakpoints.up("sm")]: {
                    paddingLeft: defaultTheme.spacing(1)
                },
                [defaultTheme.breakpoints.up("md")]: {
                    paddingLeft: defaultTheme.spacing(3)
                }
            }
        },
        MuiFilledInput: {
            underline: {
                "&:after": {
                    borderBottomColor: "#FAA61A",
                }
            }
        },
        MuiInputLabel: {
            root: {
                "&$focused": {
                    color: "#FAA61A",
                },
            },
            focused: {},
        },
        MuiListItem: {
            button: {
                "&:hover": {
                    color: "#595959 !important",
                },
            }
        },
        MuiCardHeader: {
            action: {
                marginRight: "-8px !important"
            }
        },
        MuiCardActions: {
            root: {
                padding: "8px 4px !important"
            }
        },
        MuiButtonBase: {
            root: {
                outline: "0 !important"
            }
        },
        FontAwesomeIcon: {
            root: {
                fontSize: "64px"
            }
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                whiteSpace: "nowrap"
            }
        },
        MuiTableCell: {
            root: {
                padding: "14px 16px 14px 16px"
            }
        }
    }
});

class App extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Router>
                        <div>
                            <Switch>
                                <Route path="/login" component={Loadable(Login)} />
                                <Route path="/logout" component={Logout} />
                                <Route path="/consent" component={Loadable(Consent)} />
                                <Route path="/callback" component={Loadable(AuthCallback)} />
                                <PrivateRoute path="*" component={Main} />
                            </Switch>
                            {this.props.children}
                        </div>
                    </Router>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

export default App;