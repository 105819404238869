import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Auth from "services/Auth";

class Logout extends Component {
	componentDidMount() {
		Auth.signOut();
		sessionStorage.clear();
	}

	render() {
		return (
			<Redirect to="/login" />
		);
	}
} 

export default Logout;