import React from "react";

import { PageCenteredProgress } from "components/CenteredProgress";

export const Loadable = Component => props => (
	<React.Suspense fallback={<PageCenteredProgress />}>
		<Component {...props} />
	</React.Suspense>
);

export default Loadable;