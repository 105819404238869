import _ from "lodash";

import { handleErrors } from "utils/Fetch";

import UserProfile from "services/UserProfile";

import config from "utils/Config";

class _Building {
	constructor() {
		this.callbacks = new Set(); 
	} 

	get(id) {
		// @TODO    implement caching
		return UserProfile.get().then(profile => {
			const url = config().apiRoot + "/api/ep/idf/" + id;
			return fetch(url, {
				method: "GET",
				cache: "no-cache",
				credentials: "same-origin",
				referrer: "no-referrer",
				headers: { "Content-Type": "application/json;charset=UTF-8" },
			})
				.then(handleErrors)
				.then(response => response.json())
				.then(building => {
					building.profile = _.find(profile.buildings, { "id": building.id });
					return building;
				});
		});
	}

	setActive(id) {
		sessionStorage.setItem("activeBuilding", id);

		this.callbacks.forEach(cb => {
			cb.call(id);
		});
	}

	getActive() {
		const building = sessionStorage.getItem("activeBuilding");
		if(building) {
			return this.get(building);
		}

		return UserProfile.get()
			.then(profile => profile.default)
			.then(id => this.get(id));
	}

	addListener(callback) {
		this.callbacks.add(callback);
	}

	removeListener(callback) {
		this.callbacks.delete(callback);
	}
}

const Building = new _Building();

export default Building;