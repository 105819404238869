function getEnvVar(variableName) {
	if (window.env && window.env[variableName]) {
		return window.env[variableName];
	}
	return;
}

function getEnv(name) {
	return `${getEnvVar(name) || process.env[name]}`;
}

function config() {
	return {
		apiRoot: getEnv("REACT_APP_API_ROOT"),
		authCallback: getEnv("REACT_APP_AUTH_CALLBACK"),
		version: process.env.REACT_APP_VERSION,
	};
}

export default config;