import ClientOAuth2 from "client-oauth2";
import hash from "hash.js";
import base64url from "base64url";
import srs from "secure-random-string";

import config from "utils/Config";
import UserProfile from "services/UserProfile";

import safeBuffer from "safe-buffer";

import { handleErrors } from "utils/Fetch";

class _Auth {
	constructor() {
		this.isAuthenticated = this.isAuthenticated.bind(this);
		this.getTokenInfo = this.getTokenInfo.bind(this);
		this.authorize = this.authorize.bind(this);
		this.requestToken = this.requestToken.bind(this);
		this.sign = this.sign.bind(this);
		this.signOut = this.signOut.bind(this);
		this.signOut = this.signOut.bind(this);

		this.client = new ClientOAuth2({
			clientId: "ba22cdc5-e06d-4685-9f28-3b8c93c83581",
			accessTokenUri: config().apiRoot + "/oauth2/token",
			authorizationUri: config().apiRoot + "/oauth2/auth",
			revokeUri: config().apiRoot + "/oauth2/revoke",
			redirectUri: config().authCallback,
			scopes: ["frontend"]
		});

		this.buffer = safeBuffer.Buffer;
		if (typeof this.buffer === "function") {
			this.btoa = (string) => {
				return this.buffer.from(string).toString("base64");
			};  
		} else {
			this.btoa = window.btoa;
		}
	}

	getCodeVerifier() {
		return sessionStorage.getItem("code_verifier");
	}

	getState() {
		return sessionStorage.getItem("state");
	}

	saveToken(token) {
		sessionStorage.setItem("token", token);
	}

	getTokenInfo() {
		if(this.token === undefined) {
			try {
				const accessToken = sessionStorage.getItem("token");

				if (accessToken) {
					const payload = JSON.parse(window.atob(accessToken.split(".")[1]));
					this.token = this.client.createToken(
						accessToken, undefined, "bearer", {
							subject: payload.sub
						});
					this.token.expiresIn(new Date(payload.exp * 1000));
				}
			} catch (ex) {
				this.token = undefined;
			}
		}

		return this.token;
	}

	getSubject() {
		const token = this.getTokenInfo();
		if(token) {
			return token.data.subject;
		} else {
			return "";
		}
	}

	sign(request) {
		const token = this.getTokenInfo();
		if(token) {
			token.sign(request);
		}
	}

	isAuthenticated() {
		const token = this.getTokenInfo();

		if(token && !token.expired()) {
			return true;
		} else {
			this.token = undefined;
			return false;
		}
	}

	static generateState() {
		return srs({ alphanumeric: true, length: 43 });
	}

	static generateCodeChallenge() {
		let codeVerifier = srs({ alphanumeric: true, length: 48 });
		let h = hash.sha256().update(codeVerifier).digest();
		let codeChallenge = base64url(h);
		return { 
			code_verifier: codeVerifier, 
			code_challenge: codeChallenge };
	}

	authorize() {
		if(!this.isAuthenticated()) {
			let state = _Auth.generateState();
			let challenge = _Auth.generateCodeChallenge();
			let uri = this.client.code.getUri({
				query: { 
					code_challenge: challenge.code_challenge,
					code_challenge_method: "S256"
				},
				state: state
			});

			sessionStorage.setItem("state", state);
			sessionStorage.setItem("code_verifier", challenge.code_verifier);

			return uri;
		}

		return "/";
	}

	getSubjectInfo() {
		let subject = sessionStorage.getItem("subject");

		try {
			if(subject) {
				subject = JSON.parse(subject);
			}
		} catch(error) {
			sessionStorage.removeItem("subject");
			subject = null;
		}

		if(subject) {
			return Promise.resolve(subject);
		} 

		const token = this.getTokenInfo();
		if(!token) {
			return Promise.resolve(null);
		}

		const url = config().apiRoot + "/api/auth/identity/" + token.data.subject;
		return fetch(url, {
			method: "GET",
			cache: "no-cache",
			credentials: "same-origin"
		})
			.then(handleErrors)
			.then(response => response.json())
			.then(data => {
				sessionStorage.setItem("subject", JSON.stringify(data));
				return data;
			});
	}

	requestToken(url) {
		return this.client.code.getToken(url, {
			body: {
				code_verifier: this.getCodeVerifier()
			},
			state: this.getState()
		}).then((token) => {
			sessionStorage.removeItem("subject");
			UserProfile.clear();
			this.saveToken(token.accessToken);
			return true;
		}).catch(() => {
			return false;
		});
	}

	static toString(str) {
		return str == null ? "" : String(str);
	}

	generateBasicAuth(username, password) {
		return "Basic " + this.btoa(_Auth.toString(username) + ":" + _Auth.toString(password));
	}

	revoke(opts) {
		const options = Object.assign({}, this.client.options, opts);
		const token = this.getTokenInfo();
		if (!token) {
			return Promise.reject(new Error("Invalid token"));
		}

		if (!token.accessToken) {
			return Promise.reject(new Error("No access token"));
		}
	
		return fetch(options.revokeUri, {
			method: "POST",
			cache: "no-cache",
			credentials: "same-origin",
			referrer: "no-referrer",
			headers: {
				"Accept": "application/json, application/x-www-form-urlencoded",
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization": this.generateBasicAuth(options.clientId, options.clientSecret)
			},
			body: {
				token: token.accessToken
			}
		});
	}

	signOut() {
		if(this.isAuthenticated()) {
			this.revoke();
		}

		this.token = undefined;

		sessionStorage.clear();
	}
}

const Auth = new _Auth();

export default Auth;