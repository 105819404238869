import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import { Lock, LocationCity, BarChart, ChevronLeft, ExpandLess, ExpandMore } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Building from "services/Building";
import UserProfile from "services/UserProfile";
import config from "utils/Config";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
	drawer: {
		width: theme.menu.width
	},
	menu: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			flexShrink: 0,
		},
	},
	profile: {
		padding: "1rem",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	profileIcon: {
		width: "2.5rem !important",
		height: "2.5rem",
		paddingBottom: "0.5rem",
		color: theme.palette.text.secondary
	},
	header: {
		display: "flex",
		alignItems: "center",
		padding: "0 8px",
		...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
	spaceFill: {
		flexGrow: 1
	},
	nested: {
		paddingLeft: theme.spacing(4)
	},
	version: {
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(1)
	}
});

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

class MainMenu extends Component {
	state = {
		open: false,
		subject: "",
		building: "",
		expandedReports: true
	}

	constructor(props) {
		super(props);
		this.setOpen = this.setOpen.bind(this);
	}

	setOpen(open) {
		this.setState({ open });
	}

	onBuildingChanged() {
		Building.getActive().then(building => {
			this.setState({ building: building.title });
		})
	}

	componentDidMount() {
		UserProfile.get().then(profile => {
			this.setState({ subject: profile.info.name + " " + profile.info.surname })
		});

		Building.getActive().then(building => {
			this.setState({ building: building.title });
		});

		Building.addListener(this.onBuildingChanged.bind(this));
	}

	componentWillUnmount() {
		Building.removeListener(this.onBuildingChanged.bind(this));
	}

	render() {
		const { classes, theme } = this.props;
		const {
			open,
			subject,
			building,
			expandedReports
		} = this.state;

		const menu = (
			<div className={classes.menu}>
				<div className={classes.header}>
					<Hidden mdUp implementation="js">
						<IconButton onClick={() => this.setOpen(false)}>
							<ChevronLeft />
						</IconButton>
					</Hidden>
				</div>
				<Divider />
				<List disablePadding>
					<ListItem button component={Link} to={"/profile"} onClick={() => this.setOpen(false)}>
						<ListItemIcon><FontAwesomeIcon icon="user-circle" style={{ fontSize: "24px" }} /></ListItemIcon>
						<ListItemText primary={subject} secondary={building} />
						<FontAwesomeIcon icon="edit" style={{ margin: "0 8px" }} />
					</ListItem>
					<ListItem button component={Link} to={"/logout"}>
						<ListItemIcon><Lock /></ListItemIcon>
						<ListItemText>Logout</ListItemText>
					</ListItem>
				</List>
				<Divider />
				<List disablePadding>
					<ListItem button component={Link} to={"/"} onClick={() => this.setOpen(false)}>
						<ListItemIcon><FontAwesomeIcon icon="sliders-h" style={{ fontSize: "24px" }} /></ListItemIcon>
						<ListItemText>Overview</ListItemText>
					</ListItem>
					<ListItem button component={Link} to={"/buildings"} onClick={() => this.setOpen(false)}>
						<ListItemIcon><LocationCity /></ListItemIcon>
						<ListItemText>Buildings</ListItemText>
					</ListItem>
				</List>
				<Divider />
				<List disablePadding>
					<ListItem button onClick={() => this.setState({ expandedReports: !expandedReports })}>
						<ListItemIcon><BarChart /></ListItemIcon>
						<ListItemText>Reports</ListItemText>
						{expandedReports ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
				</List>
				<Collapse in={expandedReports} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<ListItem className={classes.nested} button component={Link} to={"/reports/control"} onClick={() => this.setOpen(false)}>
							<ListItemText>Control</ListItemText>
						</ListItem>
						<ListItem className={classes.nested} button component={Link} to={"/reports/sensor"} onClick={() => this.setOpen(false)}>
							<ListItemText>Sensor</ListItemText>
						</ListItem>
					</List>
				</Collapse>
				<div className={classes.spaceFill}>
				</div>
				<Typography variant="caption" className={classes.version}>
					v{config().version}
				</Typography>
			</div>
		);

		return (
			<nav>
				<Hidden mdUp implementation="js">
					<SwipeableDrawer
						anchor="left"
						open={open}
						disableBackdropTransition={!iOS} disableDiscovery={iOS}
						onOpen={() => this.setOpen(true)}
						onClose={() => this.setOpen(false)}>
						{menu}
					</SwipeableDrawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<Drawer
						variant="permanent"
						open
						className={classes.drawer}>
						{menu}
					</Drawer>
				</Hidden>
			</nav>
		);
	}
}

MainMenu.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	//history: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MainMenu);